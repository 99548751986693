import React from "react";
import "./Career.scss";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CareerCard from "../CareerCard/CareerCard";

export default function Career() {
  return (
    <section className="parcours" id="career">
      <div className="parcours__border">
        <h2>Parcours</h2>
        <div className="parcours__content">
          <CareerCard
            title={"Brevet d'Études Professionnelles"}
            text={
              "Apprentissage de plusieurs domaines : Informatique, Réseaux, Communication, Développement. "
            }
          />
          {/* <ArrowDropDownRoundedIcon className="parcours__arrow" /> */}
          <CareerCard
            title={"Baccalauréat Professionnel"}
            text={
              "Filière : Réseaux Informatiques et Systèmes Communicants, Mention : Bien"
            }
          />
          {/* <ArrowDropDownRoundedIcon className="parcours__arrow" /> */}
          <CareerCard
            title={"Formation Bac+2 OpenClassroom"}
            text={
              "Formation diplomante : Développeur Intégrateur Web, spécialisé en Javascript et React"
            }
          />
        </div>
      </div>
    </section>
  );
}
