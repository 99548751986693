import React from "react";
import "./ProjectImageModal.scss";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function ProjectImageModal({image, alt}) {
  return (
    <div className="projectImage">
      <img src={image} alt={alt} className="projectImage__image"/>
    </div>
  );
}
