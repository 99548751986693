import React from "react";
import "./CareerCard.scss";

export default function CareerCard({ title, text }) {
  return (
    <div className="careerCard">
      <div className="careerCard__formation">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
}
