import "./Modal.scss";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function Modal({ closeModal, title, component }) {
  return (
    <>
      <div className="overlay" onClick={closeModal}></div>
      <div className="modal">
        <div className="modal__section">
          <div className="modal__section__header">
            <h2>{title}</h2>
            <div className="modal__section__header__close" onClick={closeModal}>
              <CloseRoundedIcon />
            </div>
          </div>
          <div className="modal__section__content">{component}</div>
        </div>
      </div>
    </>
  );
}
