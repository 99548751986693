import "./ZoomModal.scss";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function ZoomModal({ closeModal, component }) {
  return (
    <>
      <div className="overlay" onClick={closeModal}></div>
      <div className="zoomMmodal">
        <div className="zoomMmodal__section">
          <div className="zoomMmodal__section__close" onClick={closeModal}>
            <CloseRoundedIcon />
          </div>
          <div className="zoomMmodal__section__content">{component}</div>
        </div>
      </div>
    </>
  );
}
