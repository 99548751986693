import React from "react";
import Welcome from "../../components/Welcome/Welcome";
import SkillCard from "../../components/SkillCard/SkillCard";
import Skill from "../../components/Skill/Skill";
import Project from "../../components/Project/Project";
import Career from "../../components/Career/Career";
import Footer from "../../components/Footer/Footer";
import Contact from "../../components/Contact/Contact";

export default function Home() {
  return (
    <main>
      <Welcome />
      <Skill />
      <Project />
      <Career />
      <Contact />
      <Footer />
    </main>
  );
}
