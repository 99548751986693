import React from "react";
import "./ProjectCard.scss";

export default function ProjectCard({ styles, img, alt, title, langage, description, production }) {
  return (
    <div className="slide" style={styles}>
      <div className="card">
        <img className="side side--front" src={img} alt={alt} />
        <div className="side side--back">
          <div className="side--back__content">
            <h3 className="side--back__content__title">{title}</h3>
            <p className="side--back__content__langage">Langages utilisés : {langage.map((x) => x + " ")}</p>
            <p className="side--back__content__text">
              {description}
            </p>
            <p className="side--back__content__text">
              {production}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
