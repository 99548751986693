import { useState } from "react";
import "./ProjectGalleryModal.scss";
import { createPortal } from "react-dom";
import ProjectImageModal from "../ProjectImageModal/ProjectImageModal";
import ZoomModal from "../ZoomModal/ZoomModal";

export default function ProjectGalleryModal({ data }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleImageClick = (index) => {
    setShowModal(true);
    setSelectedImageIndex(index);
  };

  return (
    <div className="projectGallery">
      {data.pictures.map((image, index) => (
        <img
          key={index}
          className="projectGallery__image"
          src={image}
          alt=""
          onClick={() => handleImageClick(index)}
        />
      ))}
      {showModal &&
        createPortal(
          <ZoomModal
            closeModal={() => setShowModal(false)}
            title={data.projetName}
            component={
              <ProjectImageModal
                image={data.pictures[selectedImageIndex]}
                alt={data}
              />
            }
          />,
          document.body
        )}
    </div>
  );
}
