import React, { useState } from "react";
import "./Header.scss";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Ajout de l'état du menu
  return (
    <header className="header">
      <div className="header__section">
        <a href="#" className="header__section__title">
          <h1>Sass Steven</h1>
        </a>
        <nav className="header__section__nav">
          <ul className="header__section__nav__list">
            <a href="#skill">
              <li className="header__section__nav__list__element">
                Compétences
              </li>
            </a>
            <a href="#project">
              <li className="header__section__nav__list__element">Projets</li>
            </a>
            <a href="#career">
              <li className="header__section__nav__list__element">Parcours</li>
            </a>
            <a href="#contact">
              <li className="header__section__nav__list__element">Contact</li>
            </a>
          </ul>
        </nav>
        <div
          className="header__mobileMenu"
          onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <MenuRoundedIcon />
        </div>
      </div>
      <div className={`header__mobile ${isMenuOpen ? "active" : ""}`}>
        <nav>
          <ul className="header__mobile__list">
            <a href="#skill" onClick={() => setIsMenuOpen(false)}>
              <li className="header__mobile__list__element">Compétences</li>
            </a>
            <a href="#project" onClick={() => setIsMenuOpen(false)}>
              <li className="header__mobile__list__element">Projets</li>
            </a>
            <a href="#career" onClick={() => setIsMenuOpen(false)}>
              <li className="header__mobile__list__element">Parcours</li>
            </a>
            <a href="#contact" onClick={() => setIsMenuOpen(false)}>
              <li className="header__mobile__list__element">Contact</li>
            </a>
          </ul>
        </nav>
      </div>
    </header>
  );
}
