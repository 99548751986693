import React from "react";
import "./Welcome.scss";
import { TypeAnimation } from "react-type-animation";

export default function Welcome() {
  return (
    <section className="welcome">
      <div className="welcome__image">
        <div className="welcome__image__content">
          <h2>Bienvenue</h2>
          <h3>Je m'appelle Sass Steven</h3>
          <h4>
            <TypeAnimation
              sequence={[
                1500,
                "Développeur Front-End",
                3000,
                "Intégrateur Web",
                4500,
                "React et Javascript",
              ]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              className="pres__type"
            />
          </h4>
          <div>
            <p>+9 mois</p>
            <p>Formation OpenClassroom</p>
          </div>
          <div>
            <p>+13</p>
            <p>Projets Réalisés</p>
          </div>
        </div>
      </div>
    </section>
  );
}
