import React from "react";
import "./Footer.scss"

export default function Footer() {
  return (
    <footer className="footer">
      <p className="footer__left">Crée avec : Html / React / Scss </p>
      <div className="footer__ref">
        <p>Mail : contact@sass-steven.fr</p>
        <p>Sass Steven</p>
      </div>
    </footer>
  );
}
