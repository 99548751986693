import React, { useState } from "react";
import "./SkillCard.scss"
// import data from "../../data/skil.json"
import { skillData } from "../../data/data"
import { createPortal } from "react-dom";
import Modal from "../Modal/Modal";
import SkillModal from "../SkillModal/SkillModal";

export default function SkillCard({index}) {
  const [showModal, setShowModal] = useState(false);
  let dataIndex = skillData[index]
  return (
    <div className="skillCard--border">
      <div className="skillCard" onClick={() => setShowModal(true)}>
        <h3>{dataIndex?.langage}</h3>
        <img src={dataIndex?.logo} alt="Logo HTML 5" />
      </div>
      {showModal &&
        createPortal(
          <Modal closeModal={() => setShowModal(false)} title={dataIndex.langage} component={<SkillModal data={dataIndex}/>} />,
          document.body
        )}
    </div>
  );
}
