import React from "react";
import "./Skill.scss";
import { skillData } from "../../data/data";
import SkillCard from "../SkillCard/SkillCard";

export default function Skill() {
  return (
    <section className="skill" id="skill">
      <div className="skill__edition">
        <h2>Compétences :</h2>
        <div className="skill__edition__content">
          {skillData.map((skillData, index) => (
            <SkillCard 
            key={index}
            index={index} 
            />
          ))}
        </div>
      </div>
    </section>
  );
}
