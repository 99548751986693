// import des images compétences
import html from "../img/skill/html.png";
import css3 from "../img/skill/css.png";
import javascript from "../img/skill/javascript.png";
import react from "../img/skill/react.png";
import redux from "../img/skill/redux.png";
import git_github from "../img/skill/git.png";
import sass from "../img/skill/sass.png";
import figma from "../img/skill/figma.png";

// import des images projets
import argentBank from "../img/project/ArgentBank.png";
import booki from "../img/project/Booki.png";
import kasa from "../img/project/Kasa.png";
import ninaCarducci from "../img/project/NinaCarducci.jpg";
import ohMyFood from "../img/project/OhMyFood.png";
import sophieBluel from "../img/project/SophieBluel.png";

// kasa :
import kasa1 from "../img/project/Kasa/kasa1.png";
import kasa2 from "../img/project/Kasa/kasa2.png";
import kasa3 from "../img/project/Kasa/kasa3.png";
import kasa4 from "../img/project/Kasa/kasa4.png";

// sophieBluel :
import sophieBluel1 from "../img/project/SophieBluel/sophieBluel1.png";
import sophieBluel2 from "../img/project/SophieBluel/sophieBluel2.png";
import sophieBluel3 from "../img/project/SophieBluel/sophieBluel3.png";
import sophieBluel4 from "../img/project/SophieBluel/sophieBluel4.png";
import sophieBluel5 from "../img/project/SophieBluel/sophieBluel5.png";
import sophieBluel6 from "../img/project/SophieBluel/sophieBluel6.png";
import sophieBluel7 from "../img/project/SophieBluel/sophieBluel7.png";
import sophieBluel8 from "../img/project/SophieBluel/sophieBluel8.png";

// données des compétences
export const skillData = [
  {
    id: "ertg65847dh",
    langage: "Html 5",
    logo: html,
    description: [
      "Maîtrise HTML5 : Utilisation avancée des balises HTML5 pour structurer des pages web modernes.",
      "Sémantique et accessibilité : Utilisation appropriée des balises sémantiques pour améliorer la lisibilité et l'accessibilité.",
      "Formulaires interactifs : Création de formulaires interactifs en utilisant les fonctionnalités HTML5 pour une meilleure expérience utilisateur.",
      "Multimédia intégré : Intégration de contenus multimédias tels que vidéos, audios et images avec les balises HTML adéquates.",
    ],
  },
  {
    id: "ertg65847dh",
    langage: "Css 3",
    logo: css3,
    description: [
      "Maîtrise de CSS3 : Utilisation avancée des propriétés CSS3 pour la mise en forme et la présentation des pages web.",
      "Responsive Design : Création de mises en page réactives pour une expérience utilisateur optimale sur différents appareils.",
      "Flexbox et Grid : Utilisation experte des modèles de dispositions modernes pour des mises en page flexibles et complexes.",
      "Animations et transitions : Utilisation de CSS pour créer des animations, des transitions et des effets visuels attrayants.",
    ],
  },
  {
    id: "hg5r6ed784hjd",
    langage: "Javascript",
    logo: javascript,
    description: [
      "Maîtrise HTML5 : Structuration sémantique des pages web.",
      "CSS3 (Flexbox/Grid) : Mise en forme avancée, layouts responsives.",
      "Responsive Design : Adaptabilité sur tous les appareils.",
      "Compatibilité multi-navigateurs : Résolution des problèmes de compatibilité.",
      "Débogage/Inspection : Identification rapide des problèmes de style et de mise en page.",
      "Accessibilité : Respect des normes pour une utilisation plus large.",
    ],
  },
  {
    id: "5k7tuy5rft",
    langage: "React",
    logo: react,
    description: [
      "Maîtrise de React : Développement d'interfaces utilisateur réactives.",
      "Composants réutilisables : Création de modules modulaires pour extensibilité.",
      "Routing avec React Router : Mise en place de la navigation entre les différentes vues de l'application.",
      "Connaissances avancées en JavaScript (ES6+) : Utilisation des fonctionnalités modernes pour optimiser le code React.",
    ],
  },
  {
    id: "k574ytg5j4fgj",
    langage: "Redux",
    logo: redux,
    description: [
      "Configuration et Utilisation de Redux : Mise en place de stores Redux pour gérer l'état global de l'application de manière efficace.",
      "Actions et Reducers : Création d'actions et de reducers pour décrire les changements d'état de l'application.",
      "Intégration avec React : Utilisation de React-Redux pour connecter les composants React avec le store Redux.",
      "Débogage Redux : Utilisation d'outils comme Redux DevTools pour déboguer, inspecter et suivre les actions et l'état de l'application.",
      "Connaissance pratique de redux-persist pour la sauvegarde et la restauration persistante de l'état Redux",
    ],
  },
  {
    id: "5k7tuy5rft",
    langage: "Git / Github",
    logo: git_github,
    description: [
      "Gestion des dépôts : Création, gestion et maintenance des dépôts (repositories) sur GitHub.",
      "Collaboration : Utilisation d'outils de collaboration tels que pull requests, issues et forks pour travailler efficacement en équipe.",
      "Maîtrise de Git : Utilisation avancée de Git pour la gestion du contrôle de version et la gestion des branches.",
      "Résolution de conflits : Capacité à résoudre efficacement les conflits de fusion (merge conflicts) dans le code.",
    ],
  },
  {
    id: "qzgsg78s785g",
    langage: "Sass",
    logo: sass,
    description: [
      "Maîtrise de Sass : Utilisation avancée du préprocesseur Sass pour écrire des feuilles de style CSS plus efficaces et modulaires.",
      "Variables Sass : Utilisation de variables pour stocker et réutiliser des valeurs, simplifiant ainsi la maintenance du code.",
      "Nesting : Structuration claire du code en utilisant le nesting pour définir des règles CSS imbriquées, améliorant la lisibilité.",
      "Mixins et fonctions : Création de mixins et de fonctions pour réutiliser des blocs de styles et simplifier les modifications globales.",
    ],
  },
  {
    id: "suyfviquebzfq",
    langage: "Figma",
    logo: figma,
    description: [
      "Maîtrise de Figma : Utilisation intermédiaire de l'outil Figma pour créer des maquettes et des composants.",
      "Maîtrise du DevMode : Utilisation du DevMode pour une intégration plus performante et rapide.",
      "Design : Des bases solides en design, telles que la roue chromatique ou des thèmes afin de proposer des designs simples et professionnels.",
    ],
  },
];

// données des projets

export const projectData = [
  {
    id: "8tj7d4sge894",
    projetName: "Booki",
    projetNumber: 3,
    cover: booki,
    link: {
      github: "https://github.com/StevenSass/Booki",
      site: "https://stevensass.github.io/Booki/",
    },
    langage: ["Html", "Css"],
    description: "Description : Booki, site vitrine d'hotel.",
    production: "Realisations : Intégration de maquettes Figma en HTML et CSS.",
  },
  {
    id: "6785gy4j",
    projetName: "OhMyFood",
    projetNumber: 4,
    cover: ohMyFood,
    link: {
      github: "https://github.com/StevenSass/OhMyFood",
      site: "https://stevensass.github.io/OhMyFood/",
    },
    langage: ["Html", "Sass"],
    description:
      "Description : OhMyFood, site de réservation de menus dans différents restaurants.",
    production:
      "Realisations : Intégration de maquettes Figma, et mise en place de diverses animations.",
  },

  {
    id: "87hj4dh4d",
    projetNumber: 6,
    projetName: "Sophie Bluel",
    cover: sophieBluel,
    link: {
      github: "https://github.com/StevenSass/Sophie-Bluel",
    },
    pictures: [
      sophieBluel1,
      sophieBluel2,
      sophieBluel3,
      sophieBluel4,
      sophieBluel5,
      sophieBluel6,
      sophieBluel7,
      sophieBluel8,
    ],
    langage: ["Html", "Css", "Javascript"],
    description: "Description : Site portfolio d’une architecte d’intérieur.",
    production:
      "Realisations : Intégration de maquettes Figma et creation d'un systeme de login avec token.",
  },
  {
    id: "l98yuh4gk984g",
    projetNumber: 8,
    projetName: "Kasa",
    cover: kasa,
    link: {
      github: "https://github.com/StevenSass/Kasa",
    },
    pictures: [kasa1, kasa2, kasa3, kasa4],
    langage: ["Html", "Scss", "React"],
    description:
      "Kasa : Site web de location d’appartements entre particuliers.",
    production:
      "Realisations : Intégration de maquettes Figma avec React et Sass.",
  },
  {
    id: "578j4df9t84j",
    projetName: "Nina Carducci",
    projetNumber: 9,
    cover: ninaCarducci,
    link: {
      github: "https://github.com/StevenSass/Nina-Carducci",
      site: "https://stevensass.github.io/Nina-Carducci/",
    },
    langage: ["Html", "Css", "Javascript"],
    description: "Nina Carducci : Portfolio d'une photographe.",
    production:
      "Realisations : Seo, mise en place du référencement local et amélioration de la visibilité en ligne",
  },
  {
    id: "tj9684fdrg68514",
    projetNumber: 11,
    projetName: "Argent Bank",
    cover: argentBank,
    link: {
      github: "https://github.com/StevenSass/Argent-Bank",
    },
    langage: ["Html", "Css", "React", "Redux:Toolkit"],
    description: "Argent Bank : Site bancaire en creation.",
    production:
      "Réalisations : Intégration de maquettes Figma en React, développement d'un store Redux interagissant avec un backend de login et de profil utilisateur.",
  },
];
