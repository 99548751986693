import React from "react";
import "./SkillModal.scss"

export default function SkillModal({data}) {
  return (
    <ul className="SkillCardContent">
      {data.description.map((langage, index) => (
        <li key={index + langage}>{langage}</li>
      ))}
    </ul>
  );
}
