import React, { useEffect, useState } from "react";
import "./Project.scss";
import { projectData } from "../../data/data";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import GitHubIcon from "@mui/icons-material/GitHub";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ProjectCard from "../ProjectCard/ProjectCard";
import { Link } from "react-router-dom";
import { isEmpty } from "../../utils/isEmpty";
import { createPortal } from "react-dom";
import ProjectGalleryModal from "../ProjectGalleryModal/ProjectGalleryModal";
import Modal from "../Modal/Modal";

export default function Project() {
  const [isHovered, setIsHovered] = useState(false);
  const [count, setCount] = useState(0);
  const [slideHeight, setSlideHeight] = useState(0);
  const [maxSlideHeight, setMaxSlideHeight] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // permet de prendre les information au premier chargement de la page
  useEffect(() => {
    TakeInformations();
  }, []);

  function TakeInformations() {
    // recuperer le nombres d'images
    const imageNumber = document.querySelectorAll(".slide").length;
    setMaxSlideHeight(imageNumber);
    // recuperer la dimension d'une image
    const slides = document.querySelector(".slide");
    setSlideHeight(slides.clientHeight);
  }

  function NextSlide() {
    TakeInformations();
    setCount(count === maxSlideHeight - 1 ? 0 : count + 1);
  }

  function PreviousSlide() {
    TakeInformations();
    setCount(count === 0 ? maxSlideHeight - 1 : count - 1);
  }

  // gestion du style pour l'animation de translateY
  const styles = {
    transform: `translateY(${count * -slideHeight}px)`,
  };

  // gestion et création des dots
  const dots = [];
  for (let i = 0; i < projectData.length; i++) {
    const active = i === count ? "active" : "";
    dots.push(<div key={i} className={`dot ${active}`}></div>);
  }

  // gère la transition automatique du carousel, ainsi que de l'entrer ou sortie de la souris afin de verouillés les transitions automatiques
  useEffect(() => {
    let intervalId;
    if (!isHovered) {
      intervalId = setInterval(() => {
        NextSlide();
      }, 5000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [count, isHovered]);

  return (
    <section className="projets" id="project">
      <h2>Projets</h2>
      <div className="projets__border">
        <div
          className="carousel"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <div className="carousel__link">
            {!isEmpty(projectData[count].link.github) ? (
              <Link to={projectData[count].link.github} target="_blank">
                <GitHubIcon />
              </Link>
            ) : (
              <></>
            )}
            {!isEmpty(projectData[count].link.site) ? (
              <Link to={projectData[count].link.site} target="_blank">
                <OpenInNewIcon />
              </Link>
            ) : (
              <></>
            )}
            {!isEmpty(projectData[count].pictures) ? (
              <>
                <CollectionsRoundedIcon onClick={() => setShowModal(true)} />
                {showModal &&
                  createPortal(
                    <Modal
                      closeModal={() => setShowModal(false)}
                      title={projectData[count].projetName}
                      component={
                        <ProjectGalleryModal data={projectData[count]} />
                      }
                    />,
                    document.body
                  )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="carousel__main">
            <ArrowBackIosNewRoundedIcon
              onClick={PreviousSlide}
              className="arrowUp"
            />
            <div className="slider">
              {projectData.map((x, index) => (
                <ProjectCard
                  styles={styles}
                  img={projectData[index].cover}
                  alt={projectData[index].projetname}
                  description={projectData[index].description}
                  production={projectData[index].production}
                  title={projectData[index].projetName}
                  langage={projectData[index].langage}
                  key={index + x}
                />
              ))}
            </div>
            <ArrowBackIosNewRoundedIcon
              onClick={NextSlide}
              className="arrowDown"
            />
          </div>
          <div className="carousel__dots">{dots}</div>
        </div>
      </div>
    </section>
  );
}
